<template>
    <b-modal centered ok-only v-model="showMoreModal" :title="translate(product.name)" :id="`collapse-${product.id}-inner`" class="mt-2">
        <div :class="{'additional-info': true, 'in-scan': inScan}">
            <template v-if="ingredients || allergens || nutriments">
                <b>{{$t("ingredientsLabel")}} :<br></b>
                <div v-if="ingredients" v-html="ingredients" class="my-2"></div>
                <span v-else>-<br></span>
                <b>{{$t("allergensLabel")}} :<br></b>
                <div v-if="allergens" class="my-2">{{allergens}}</div>
                <span v-else>-<br></span>
                <b>{{$t("nutrimentsLabel")}} :<br></b>
                <div class="my-2 nutriments">
                    <table class="nutriments-table">
                        <thead class="nutriments-table-head">
                            <td>{{$t("per")}}</td>
                            <td class="value">100g</td>
                        </thead>
                        <tbody class="nutriments-table-body">
                            <tr v-for="(nutriment, key, id) in nutriments" :key="id">
                                <td>{{translate(nutriment.name)}}</td>
                                <td class="value">{{nutriment.value}} {{nutriment.unit}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </template>
            <div v-if="formattedDescription" class="accordion-info" role="tablist">
                <template v-for="(desc, key, id) in formattedDescription">
                    <div :key="key">
                        <div block v-b-toggle="`accordion-${id}`" class="acc-header">{{key}}</div>
                        <b-collapse :visible="id == 0" :id="`accordion-${id}`" class="p-2 accordion-body" accordion="my-accordion" role="tab">
                            <template v-for="(subdesc, subkey) in desc">
                                <div :key="subkey" v-if="subkey != '' && key == $t('rating') " class="my-2">
                                    <div v-if="subkey == $t('averageRating')" :style="`--rating: ${subdesc.split('/')[0]};`" class="my-2 rating">
                                        <span>{{$t("averageRating")}} : <span class="average-rating">{{subdesc}}</span></span>
                                        <div class="stars"></div>
                                    </div>
                                    <span v-else><strong>{{subkey}}:</strong> {{subdesc}}</span>
                                </div>
                                <div  :key="subkey" v-else-if="subkey != ''" class="my-3 desc-line">
                                    <span class="mx-2"><strong>{{subkey}}</strong></span>
                                    <span>{{subdesc}}</span>
                                </div>
                            </template>
                        </b-collapse>
                    </div>
                </template>
                <template v-if="crossSellProducts && crossSellProducts.length >0">
                    <div block v-b-toggle="`accordion-cross-sell`" class="acc-header">{{$t("similarProducts")}}</div>
                    <b-collapse :visible="isEmpty(formattedDescription)" :id="`accordion-cross-sell`" class="p-2 accordion-body" accordion="my-accordion" role="tab">
                        <div class="similar-produtcs">
                            <ProductBloc v-for="product in crossSellProducts" :key="product.id" :product="product" withBorder inCrossSell fullWidth inCarousel @updateQty="basketChange()"> </ProductBloc>
                        </div>
                    </b-collapse>
                </template>
            </div>
        </div>
        <template #modal-footer>
            <div class="additional-info-modal-footer">
                <template v-if="qty > 0">
                    <div class="product-qty-wrapper flex-row-reverse">
                        <span class="icon-plus icon product-plus-icon" @click="incQty"></span>
                        <span class="mx-3 product-qty">{{qty}}</span>
                        <span class="icon-moins icon product-minus-icon" @click="decQty"></span>
                    </div>
                </template>
                <template v-else>
                    <span class="add-btn" @click="incQty">{{$t("addToBasketLabel")}}</span>
                </template>
            </div>
        </template>
    </b-modal>
</template>
<script>
import {getProductQuantity, addProductToLocalBasket, decreaseProductFromLocalBasket} from "@/assets/js/Food/Basket"
import {getCrossSellProducts} from "@/assets/js/SearchProduct"
import ProductBloc from "./ProductBloc.vue";
export default {
    components: {ProductBloc},
    data(){
        return {
            showMoreModal: true,
            ingredients: null,
            allergens: null,
            nutriments: null,
            qty: this.getQty(),
            crossSellProducts: []
        }
    },
    props: {
        product: {
            type: Object
        },
        inScan: {
            type: Boolean,
            default: false
        }
    },
    created(){
        this.fetchData(this.product.ean).then(() => {
            this.ingredients = this.getIngredients()
            this.allergens = this.getAllergens()
            this.nutriments = this.getNutrimentsFor100g()
            getCrossSellProducts(this.product.id, crossSellProducts => {
                this.crossSellProducts = crossSellProducts
            })
        })
    },
    methods: {
        incQty(){
            sessionStorage.setItem("DKC_food_suspect_basket", true);
            addProductToLocalBasket(this.product)
            this.qty = this.getQty()
            this.$root.$emit("updateQty")
        },
        decQty(){
            sessionStorage.setItem("DKC_food_suspect_basket", true);
            decreaseProductFromLocalBasket(this.product)
            this.qty = this.getQty()
            this.$root.$emit("updateQty")
        },
        getQty(){
            if(this.forcedQty){
                return this.forcedQty;
            }else{
                return getProductQuantity(this.product)
            }
        },
        formatDesc(){
            let description = null
            let desc = this.translate(this.product.description)
            if(desc){
                description = {}
                let splitDescription = desc.split("--end--")
                splitDescription.forEach(desc => {
                    let subDesc = {}
                    let titleMatches = desc.match(/\{{(.*?)\}}/);
                    if(titleMatches){
                        let splitSubDesc = desc.split("}\n")[1].split("\n")
                        splitSubDesc.forEach(sub => {
                            if(sub.split(":")[1]){
                                subDesc[sub.split(":")[0].split("?").join("")] = sub.split(":")[1].split("?").join("")
                            }
                        })
                        description[titleMatches[1]] = subDesc
                    }
                })
            }
            return description
        },
        basketChange(){
            this.$root.$emit("updateQty")
        },
        isEmpty(obj){
            return obj && Object.keys(obj).length === 0
        }
    },
    computed:{
        formattedDescription(){
            return this.formatDesc()
        }
    },
    watch:{
        nutriscore(){
            this.showMoreModal = this.nutriscore != null
        },
        showMoreModal(){
            if(!this.showMoreModal){
                this.$emit("hide", false)
            }
        }
    }
    
}
</script>
<style scoped>
    .accordion-body{
        max-height: 400px;
        overflow: scroll;
    }
    .more-info{
        text-decoration: underline;
        font-style: italic;
        font-size: .85rem;
        color: rgb(var(--hot-food-color));
    }
    .additional-info{
        font-size: .9rem;
    }
    .additional-info p{
        width: 100%;
        display: flex;
        flex-direction: column;
    }
    .additional-info.in-scan{
        max-height: 150px;
        overflow: scroll;
    }
    .acc-header {
        background: rgb(var(--hot-food-color));
        margin: 2px 0;
        border-radius: 12px;
        color: white;
        font-weight: bold;
        padding: 8px;
    }
    .product-bloc {
        position: relative;
        z-index: 10;
    }
    .additional-info{
        font-size: .95rem;
    }
    .desc-line{
        display: grid;
        grid-template-columns: 1fr 1fr;
    }
    .nutriments-table{
        width: 100%;
    }
    .nutriments-table .nutriments-table-head{
        background: rgb(var(--hot-food-color)) !important;
        font-weight: bold;
        color: white;
    }
    .nutriments-table .nutriments-table-body{
        border: 1px solid;
        border-top: none;
    }
    .nutriments-table .value{
        text-align: center;
        border-left: 1px solid;
    }
    .nutriments-table td{
        padding: 4px;
    }
    .additional-info-modal-footer{
        width: 100%;
        justify-content: center;
        display: flex;
    }
    .add-btn{
        background: rgb(var(--hot-food-color));
        color: white;
        font-size: .9rem;
        padding: 8px;
        border-radius: 50px;
        font-weight: bold;
    }
    .stars {
        --percent: calc(var(--rating) / 5 * 100%);
        display: inline-block;
        font-size: 32px;
        font-family: Times; 
        line-height: 1;
    }
    .stars:before {
        content: '★★★★★';
        letter-spacing: 3px;
        background: linear-gradient(90deg, rgb(var(--hot-food-color)) var(--percent), #dcdcdc var(--percent));
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
    .rating{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }
    .rating span{
        font-weight: bold;
        font-size: 1.5rem;
    }
    .average-rating{
        color: rgb(var(--hot-food-color));
    }
    .similar-produtcs{
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 16px;
    }
</style>