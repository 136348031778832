<template>
    <div id="food" class="univers">
        <NewUpdateAvailable v-if="newUpdateAvailable" />
        <Header v-if="pageInfos != null" :nbProductsCart="nbProductsCart" :description="pageInfos.description" :titleAtRight="pageInfos.titleAtRight" :showBasket="pageInfos.showBasket != false" :totalAmount="basket.totalAmount || 0" :isOffline="isOffline" @headerHeight="headerHeight = $event" :title="pageInfos.title"></Header>
        <router-view :key="$route.fullPath" :headerHeight="headerHeight" :style="{paddingTop: `${headerHeight + 16}px`}" :isOffline="isOffline" @pageCreated="handlePageCreated($event)"></router-view>
        <ModalIdentification v-if="modalShow"></ModalIdentification>
        <ModalCrossSelling v-if="productCrossSell && productCrossSell.length>0" :productCrossSell="productCrossSell"/>
        <AdditionalInfoModal v-if="showAdditionalModal" :product="productToShow.product" :inScan="productToShow.inScan" @hide="showAdditionalModal = false" @updateQty="basketChange()"/>
        <TraceabilityModal v-if="showTraceabilityModal" @hideTraceability="showTraceabilityModal = false" :ean="traceabilityEan" ></TraceabilityModal>
        <Loader :content="loaderContent" v-if="showLoader"></Loader>
        <Alert ref="Alert" :alertContent="alertContent" :alertVariant="alertVariant"></Alert>
    </div>
</template>

<script>
    import "@/assets/css/custom-food.css"
    import "@/assets/utils/addtohomescreen.css"
    import "@/assets/utils/addtohomescreen.js"
    import Header from "@/components/Food/Header";
    import ModalIdentification from "@/components/Food/ModalIdentification";
    import ModalStore from "@/components/Food/ModalStore";
    import Loader from "@/components/Common/Loader";
    import ModalCrossSelling from "@/components/Food/ModalCrossSelling";
    import AdditionalInfoModal from "@/components/Food/AdditionalInfoModal";
    import Alert from "@/components/Common/Alert";
    import NewUpdateAvailable from "@/components/Food/NewUpdateAvailable";
    import {isNotNull, getConfig} from "@/assets/js/Utils"
    import ModalCabin from "@/components/Food/ModalCabin";
    import TraceabilityModal from "./TraceabilityModal";
    var Basket = require("@/assets/js/Food/Basket");
    var qrcodeGenerator = require('qrcode-generator')
    var App = require("@/assets/js/App")
    var Stats = require("@/assets/js/Stats")
    var Utils = require("@/assets/js/Utils")
    var MQTT = require("@/assets/js/Food/MqttService")
    var Customer = require("@/assets/js/Customer")
    let idb = require("idb")
    var customer = require("@/assets/js/Customer")


    export default {
        name: "Food",
        components: {
            TraceabilityModal,
            ModalCabin, Header,ModalIdentification,Loader,ModalCrossSelling,Alert, ModalStore, NewUpdateAvailable, AdditionalInfoModal},
        data: function() {
            return{
                basket: {
                    "id": 0,
                    "totalAmount": 0,
                    "products": [],
                    "lockedProducts" :[]
                },
                nbProductsCart : 0,
                qrCodeImg :'',
                showLoader: false,
                loaderContent: "",
                modalShow: false,
                base: "",
                productCrossSell: null,
                alertContent: "",
                alertVariant: "",
                logoURL: "",
                customer: null,
                modalCabinShow: false,
                isOffline: sessionStorage.getItem("DKC_is_offline") == "true",
                keyword: null,
                showModalStore: false,
                newUpdateAvailable: false,
                headerHeight: 150,
                pageInfos: null,
                mqttWaitingPaymentSubscription: false,
                productToShow: null,
                showAdditionalModal: false,
                showTraceabilityModal: false,
                traceabilityEan: null
            }
        },
        created(){
            console.log(this.$route)
            document.dispatchEvent(new Event('x-app-rendered'))
            this.$root.$emit("bv::hide::modal", "modalIdentification")
            let i18n = {};
            Object.keys(this.langs).forEach( lang => {
                    i18n[lang] = require('@/locales/'+lang+'.json');
                    this.$root.$i18n.setLocaleMessage(lang,i18n[lang].food)
                }
            );

            if(isNotNull(localStorage.getItem("IEC_lang"))){
                this.$root.$i18n.locale = localStorage.getItem("IEC_lang");
            }else{
                this.$root.$i18n.locale = 'en';
            }
            Basket.getSuspectConfig().then( ({data}) => {
                localStorage.setItem("IEC_SUPSECT_BASKET_CONFIG", JSON.stringify(data))
            })

            let token = localStorage.getItem("IEC_TOKEN");
            document.dispatchEvent(new Event('x-app-rendered'))

            // if(!this.$route.query.inBuild){
            // //   if(!token){
            // //     setTimeout(() => {
            // //       this.modalShow = true
            // //     }, 500);
            // //   }else{
            // //     this.modalShow = false
            // //     localStorage.setItem("IEC_TOKEN", token)
            // //     localStorage.setItem("IEC_FIRSTNAME", Cookies.get("IEC_FIRSTNAME"))
            // //     localStorage.setItem("IEC_LASTNAME", Cookies.get("IEC_LASTNAME"))
            // //     localStorage.setItem("IEC_EMAIL", Cookies.get("IEC_EMAIL"))
            // //   }
            // }

            if(!this.$route.query.inBuild){
                if(!token){
                    this.$parent.showLoader = true;
                    Customer.registerCustomer({}, (data) => {
                        if(data.error){
                            this.error = true
                            this.errorMessage = data.message || null
                            this.$parent.showLoader = false
                        }else{
                            window.location.replace(window.location.origin)
                            this.$parent.showLoader = false;
                        }
                    })
                }
            }

            this.$root.$on("showMore", (e) => {
                this.handleShowAdditionalModal(e)
            })
            this.$root.$on("showTraceability", (traceabilityEan) => {
                this.traceabilityEan = traceabilityEan
                this.showTraceabilityModal = true
            })
            this.$root.$on("updateQty", () => {
                this.basketChange()
            })
            console.log(this.$mqtt)
            if (!this.mqttWaitingPaymentSubscription) {
                this.$mqtt.initMqttClient()
            }
            if(!localStorage.getItem("IEC_PRODUCTS_DELETED")){
                localStorage.setItem("IEC_PRODUCTS_DELETED", 0)
            }
            if(!localStorage.getItem("IEC_START_DATE")){
                localStorage.setItem("IEC_START_DATE", new Date())
            }

            App.saveDeviceType();

            document.addEventListener('newContentAvailable', (e) => {
                this.newUpdateAvailable = true;
            }, false);
            this.basket = Basket.getBasket();

            if(isNotNull(sessionStorage.getItem("IEC_productCrossSell"))){
                this.productCrossSell = JSON.parse(sessionStorage.getItem("IEC_productCrossSell"));
            }

            window.onunload = function(){
                this.closeHandler();
            }
        },
        mounted(){
            Utils.setAppContext(this);
            //MQTT.susbscribeReset(this);

            window.addEventListener("offline", envent => {
                this.isOffline = true
                sessionStorage.setItem("DKC_is_offline", true);
            })

            window.addEventListener("online", envent => {
                this.isOffline = false
                sessionStorage.setItem("DKC_is_offline", false)
            })

            if(isNotNull(window.location.pathname.indexOf("DKS"))){
                needReferesh = "true";
            }
            this.basket = Basket.getBasket();
            localStorage.removeItem("DKC_needPublishPoster");
            localStorage.removeItem("DKC_needRefresh");
        },
        beforeUpdate(){
            //MQTT.unsusbscribeReset();
        },
        updated(){
            //MQTT.susbscribeReset(this);
            if(!isNotNull(this.customer)){
                /*var token = localStorage.getItem("DKC_food_token");

                if(isNotNull(token)){
                    Customer.getCustomer(function(jsonCustomer){
                        if(isNotNull(jsonCustomer)){
                            self.customer = JSON.parse(jsonCustomer);
                        }
                    });
                }
                */
            }
        },
        methods: {
            basketChange: function(){
                this.basket = Basket.getBasket();
                this.count(this.basket.basketProducts);
            },
            count: function(products) {
                var count = 0;
                products.forEach(function(product) {
                    count+=product.qty;
                });
                this.nbProductsCart = count;
            },
            closeHandler:function() {
                this.basket = Basket.getBasket();
                if(isNotNull(localStorage.getItem("DKC_food_token"))){
                   /* Basket.updateBasket(function(newBasket) {
                        let tmpProducts = self.basket.products;
                        newBasket.products.forEach(function(element) {
                            tmpProducts.some(function(oldProduct, id) {
                                if(element.product.ean == oldProduct.product.ean && oldProduct.qtyLocked && oldProduct.qty == element.qty){
                                    element.qtyLocked = oldProduct.qtyLocked;
                                    tmpProducts.splice(id, 1)
                                    return true;
                                }
                            })
                        })*/
                        //newBasket.lockedProducts = self.basket.lockedProducts;
                        //localStorage.setItem("IEC_basket", JSON.stringify(self.basket));
                    //})
                }
            },
            share: function(){
                var qr = qrcodeGenerator(0, 'L');
                qr.addData(window.location.origin);
                qr.make();
                this.qrCodeImg = qr.createDataURL(10, 0);
                this.$refs.shareModal.show();
            },
            showAlert(content, type = ""){
                var variant = "";
                switch (type) {
                    case 'success':
                        variant = "success";
                        break;
                    case 'error':
                        variant = "danger";
                        break;
                    case 'info':
                        variant = "info";
                        break;
                    default:
                        variant = "info";
                }
                this.alertContent = content;
                this.alertVariant = variant;
            },
            checkDemonstrationLogo(){
                var path = Utils.getConfig().PATH_IMG + localStorage.getItem("DKC_demoId");
                this.logoURL = Utils.getConfig().DKW_URL + path;
            },
            refreshCustomer(){
                var self = this;
                var token = localStorage.getItem("DKC_food_token");
                if(isNotNull(token)){
                    Customer.getCustomer(function(jsonCustomer){
                        if(isNotNull(jsonCustomer)){
                            self.customer = JSON.parse(jsonCustomer);
                        }
                    });
                }
            },
            setStats(){
                var complexId = localStorage.getItem("DKC_complexId");
                if(isNotNull(complexId) && window.location.href.indexOf("needParameters") == -1 && this.$route.name == "FoodIndex"){
                    var complexIdParent = localStorage.getItem("DKC_complexIdParent");
                    var deviceType = localStorage.getItem("IEC_deviceType");
                    var parameters = {
                        nbPlayedDemos: 1
                    }

                    if(isNotNull(complexIdParent) && isNotNull(complexId) && complexIdParent != complexId)
                        parameters.complexIdParent = complexIdParent;

                    if(isNotNull(deviceType))
                        parameters.deviceType = deviceType;

                    Stats.setStats(parameters);
                }
            },
            getAllProducts(){
                var path = "/catalog/product/getAll";
                var url = getConfig().DKW_URL + path;
                var request = new XMLHttpRequest();
                request.onreadystatechange = () => {
                    if (request.readyState === XMLHttpRequest.DONE) {
                        if (request.status === 200 && request.response != "") {
                            this.saveAllProductsLocaly(JSON.parse(request.response));
                        }else{
                            getConfig().appContext.showAlert(getConfig().appContext.$t('errorGetCustomer'),"error");
                        }
                    }
                };
                request.open('GET', url, true);
                request.send();
            },
            saveAllProductsLocaly(products){
                if (!('indexedDB' in window)) {return null;}
                const db = idb.openDB('demokit-food', 1, {
                    upgrade(db) {
                        if (!db.objectStoreNames.contains('products')) {
                            db.createObjectStore('products', {keyPath: 'id'});
                        }
                    }
                })
                .then(db => {
                    const tx = db.transaction('products', 'readwrite');
                    Promise.all(products.map(product => tx.store.put(product)))
                    return tx.complete
                })
        },
        handlePageCreated(event){
            this.pageInfos = JSON.parse(JSON.stringify(event))
        },
        handleShowAdditionalModal(event){
            console.log(event)
            this.productToShow = event;
            this.showAdditionalModal = true;
        }
    },
    watch:{
            basket: function(newVal, oldVal) {
                //todo ?
                localStorage.getItem("IEC_basket");
                this.count(this.basket.basketProducts);
                this.$emit('chgtQty', this.nbrProducts);
                console.log(newVal)
            },
            showLoader(){
                if(this.showLoader && this.loaderContent == ""){
                    this.loaderContent = this.$t("loading")
                }else if(!this.showLoader){
                    this.loaderContent = ""
                }
            }
        }
    }

</script>
