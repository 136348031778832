import {clearCacheExceptCustomer, getConfig, isNotNull} from "./Utils";
import {clearBasket} from "./Basket";
var mqtt = require('mqtt');

var app = getConfig();

var mqttConf = {
    host: "mqtt.labs.worldline-solutions.com",
    port: 8003,
    username: "rd",
    password: "@t0s@t0s",
    rejectUnauthorized: false,
    protocol: 'wss'
}

var demoId = localStorage.getItem("DKC_demoId");
var topicScanPoster;
var topicScanProduct;
var topicPostCrossSell;
var topicScanRoom;
var topicScanValina;
var topicSelectPayment;
var topicValinaSuccess;
var topicReset;
var client;
var initOk = false;

function initTopics(){
    if(isNotNull(demoId)){
        topicScanPoster = "wldd/demokit/dks/fashion/" + demoId + "/scanPoster";
        topicScanProduct = "wldd/demokit/dks/fashion/" + demoId + "/scanProduct";
        topicPostCrossSell = "wldd/demokit/dks/fashion/" + demoId + "/postCrossSell";
        topicScanRoom = "wldd/demokit/dks/fashion/" + demoId + "/scanRoom";
        topicScanValina = "wldd/demokit/dks/fashion/" + demoId + "/scanValina";
        topicSelectPayment = "wldd/demokit/dks/fashion/" + demoId + "/selectPayment";
        topicValinaSuccess = "wldd/demokit/dks/fashion/" + demoId + "/valinaSuccess";
        topicReset = "wldd/demokit/dks/fashion/" + demoId + "/resetDemo";
        initOk = true;
    }
}

export function publishMessage(type, message = "", callback = null) {
    initTopics();
    if(initOk){
        client = mqtt.connect({
            host: mqttConf.host,
            port: mqttConf.port,
            username: mqttConf.username,
            password: mqttConf.password,
            rejectUnauthorized: mqttConf.rejectUnauthorized,
            protocol: mqttConf.protocol
        });

        errorHandler(client);

        client.on('connect', function () {
            var topic = getTopic(type);
            if(isNotNull(callback)){
                client.publish(topic, message, callback);
            }else{
                client.publish(topic, message);
            }
        });
    }
}

export function susbscribeValinaSuccess(self) {
    initTopics();
    if(initOk){
        client = mqtt.connect({
            host: mqttConf.host,
            port: mqttConf.port,
            username: mqttConf.username,
            password: mqttConf.password,
            rejectUnauthorized: mqttConf.rejectUnauthorized,
            protocol: mqttConf.protocol
        });

        errorHandler(client);

        client.subscribe(topicValinaSuccess)

        client.on('message', function () {
            self.$parent.showLoader = false;
            self.$router.push("/paymentdone");
            client.end()
        })
    }
}

export function susbscribeReset(self) {
    initTopics();
    if(initOk){
        client = mqtt.connect({
            host: mqttConf.host,
            port: mqttConf.port,
            username: mqttConf.username,
            password: mqttConf.password,
            rejectUnauthorized: mqttConf.rejectUnauthorized,
            protocol: mqttConf.protocol
        });

        errorHandler(client);

        client.subscribe(topicReset)

        client.on('message', function () {
            clearCacheExceptCustomer(self);
            clearBasket();
            client.end()
        })
    }
}

export function unsusbscribeReset() {
    initTopics();
    if(initOk){
        client = mqtt.connect({
            host: mqttConf.host,
            port: mqttConf.port,
            username: mqttConf.username,
            password: mqttConf.password,
            rejectUnauthorized: mqttConf.rejectUnauthorized,
            protocol: mqttConf.protocol
        });

        errorHandler(client);

        client.unsubscribe(topicReset)
    }
}

function getTopic(type) {
    var topic;
    switch (type) {
        case "SCAN_POSTER":
            topic = topicScanPoster;
            break;
        case "SCAN_PRODUCT":
            topic = topicScanProduct;
            break;
        case "POST_CROSS_SELL":
            topic = topicPostCrossSell;
            break;
        case "SCAN_ROOM":
            topic = topicScanRoom;
            break;
        case "SELECT_PAYMENT":
            topic = topicSelectPayment;
            break;
        case "SCAN_VALINA":
            topic = topicScanValina;
            break;
    }
    return topic;
}

function errorHandler(client){
    client.stream.on('error', function (err) {
        if(err){
            client.end(true);
            app.appContext.showAlert(app.appContext.$t('errorOnConnectMQTT'),"error");
        }
    });
}