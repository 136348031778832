<template>
    <b-modal
        v-model="showModal"
        :title="$t('traceabilityModalTitle')"
        centered
        ok-only
        class="mt-2">
        <h5 class="font-weight-bold">{{$t('traceabilityModalDescriptionLabel')}}</h5>
        <p class="small-custom">{{$t('traceabilityModalDescriptionValue')}}</p>

        <h5 class="font-weight-bold mt-8">{{$t('traceability.'+ean+'.stepsLabel')}}</h5>
        <ul class="timeline">
            <li class="d-flex flex-row">
                <div class="d-flex flex-column">
                    <span class="font-weight-bold pb-2">{{$t('traceability.'+ean+'.step1Label')}}</span>
                    <span class="small-custom">{{$moment().subtract(33, 'days').format('DD MMMM YYYY')}}</span>
                </div>
                <img :src="require('@/assets/img/flag_india.png')" class="flag-picto" v-if="ean === '3427246118004'"/>
            </li>
            <li class="d-flex flex-row">
                <div class="d-flex flex-column">
                    <span class="font-weight-bold pb-2">{{$t('traceability.'+ean+'.step2Label')}}</span>
                    <span class="small-custom">{{$moment().subtract(30, 'days').format('DD MMMM YYYY')}}</span>
                </div>
                <img :src="require('@/assets/img/flag_morocco.png')" class="flag-picto" v-if="ean === '3427246118004'"/>
            </li>
            <li class="d-flex flex-row">
                <div class="d-flex flex-column">
                    <span class="font-weight-bold pb-2">{{$t('traceability.'+ean+'.step3Label')}}</span>
                    <span class="small-custom" v-if="ean === '3427246118004'">{{$moment().subtract(28, 'days').format('DD MMMM YYYY')}}</span>
                    <ul style="padding-inline-start: 20px" v-else>
                        <li class="small-custom">{{$t('traceability.'+ean+'.step3Start', {start: $moment().subtract(30, 'days').format('DD MMMM YYYY')})}}</li>
                        <li class="small-custom">{{$t('traceability.'+ean+'.step3End', {end: $moment().subtract(8, 'days').format('DD MMMM YYYY')})}}</li>
                    </ul>
                </div>
                <img :src="require('@/assets/img/flag_italy.png')" class="flag-picto" v-if="ean === '3427246118004'"/>
            </li>
            <li class="d-flex flex-row">
                <div class="d-flex flex-column">
                    <span class="font-weight-bold pb-2">{{$t('traceability.'+ean+'.step4Label')}}</span>
                    <span class="small-custom">{{$moment().subtract(3, 'days').format('DD MMMM YYYY')}}</span>
                </div>
                <img :src="require('@/assets/img/flag_french.png')" class="flag-picto" v-if="ean === '3427246118004'"/>
            </li>
        </ul>

        <div class="d-flex">
            <div>
                <h5 class="font-weight-bold">{{$t('traceability.'+ean+'.AOCLabel')}}</h5>
                <p class="small-custom" v-html="$t('traceability.'+ean+'.AOCValue')"></p>
            </div>
            <img :src="require('@/assets/img/oeko.png')" class="aoc-picto" v-if="ean === '3427246118004'"/>
            <img :src="require('@/assets/img/aoc.jpg')" class="aoc-picto" v-else/>
        </div>

        <h5 class="font-weight-bold">{{$t('traceabilityModalLocalisationLabel')}}</h5>
        <p class="small-custom" v-html="$t('traceability.'+ean+'.localisationValue')"></p>

        <img :src="require('@/assets/img/map_tshirt.png')" class="map-image" v-if="ean === '3427246118004'"/>
        <img :src="require('@/assets/img/map-big-traceability.jpg')" class="map-image" v-else/>

        <template #modal-footer>
            <span class="ok-btn" @click="showModal = false">OK</span>
        </template>
    </b-modal>
</template>
<script>
export default {
    props: ['ean'],
    data(){
        return {
            showModal: true
        }
    },
    created(){
        console.log(this.$moment.locale())
    },
    methods: {
    },
    computed:{
    },
    watch:{
        showModal(){
            if(!this.showModal){
                this.$emit("hideTraceability")
            }
        }
    }

}
</script>
<style scoped>
    ul.timeline {
        list-style-type: none;
        position: relative;
    }
    ul.timeline:before {
        content: ' ';
        background: #90989b;
        display: inline-block;
        position: absolute;
        left: 26px;
        width: 2px;
        height: 100%;
        z-index: 400;
    }
    ul.timeline > li {
        margin: 20px 0;
        padding-left: 20px;
        display: flex;
        flex-direction: column;
    }
    ul.timeline > li:before {
        content: ' ';
        background: white;
        display: inline-block;
        position: absolute;
        border-radius: 50%;
        border: 3px solid #90989b;
        left: 20px;
        width: 15px;
        height: 15px;
        z-index: 400;
    }
    .small-custom{
        font-size: 14px;
    }
    .aoc-picto{
        width: 50px;
        height: 50px;
        margin: 0 0 0 auto;
        align-self: center;
    }
    .flag-picto{
        width: 50px;
        margin: 0 0 0 auto;
        align-self: center;
    }
    .map-image{
        width: 100%;
    }
    .ok-btn{
        background: rgb(var(--hot-food-color));
        color: white;
        font-size: .9rem;
        padding: 8px;
        border-radius: 50px;
        font-weight: bold;
        min-width: 75px;
        text-align: center;
    }
</style>
