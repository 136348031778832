<template>
    <div class="new-update-wrapper">
        <div class="new-update-text">
            <p class="explain-text">New content available, the page will be automatically refreshed in {{time}}s ...</p>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            time: 3,
            clockParams: {
                month: "long",
                weekday: "long",
                day: "2-digit",
                year: "numeric",
                hour: "2-digit",
                minute: "2-digit",
                second: "2-digit"
            }
        }
    },
    created() {
        let lang = localStorage.getItem("IEC_lang") || this.$route.query.lang || "en"
        setInterval(() => { this.time>0 ? this.time-- : 0}, 999);
        setTimeout(()=> document.location.reload(), 3000)
    },
}
</script>