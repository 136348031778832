<template>
    <div ref="header" class="food-header">
        <header v-if="!$route.query.needParameters">
            <div class="global-actions">
                <div @click="showSidebar = true" :style="{backgroundImage: `url('${require('@/assets/img/menu-icon.png')}')`}" class="brand-logo"></div>
                <div v-if="showBasket" :class="{'header-basket': true}" @click="$router.push(`/basket`)">
                    <template v-if="!showStep">
                        <div class="icon-wrapper">
                            <span class="icon-panier"></span>
                            <span :class="{'nbr-products': true, 'shake': animateCart}">{{nbProductsCart}}</span>
                        </div>
                        <span class="total-amount">{{fromattedTotalAmount}}€</span>
                    </template>
                    <template v-else>
                        <span class="header-step">{{stepToShow}}</span>
                    </template>
                </div>
                <h3 class="title-at-right" v-if="!showBasket && titleAtRight">{{title}} </h3>
            </div>
            <div class="page-actions">
                <div class="page-info">
                    <h3 v-if="title != null && !titleAtRight">{{title}}</h3>
                    <p v-if="description != null">{{description}}</p>
                </div>
            </div>
        </header>
        <div v-if="showSidebar" class="sidebar-wrapper" @click.self="showSidebar = false">
            <div class="sidebar py-2">
                <div class="sidebar-header" @click="showSidebar = false">
                    <span @click="showSidebar = true" class="icon-fleche-gauche"></span>
                    <div class="sidebar-logo" :style="{backgroundImage: `url('${logoUrl}')`}"></div>
                    <b-dropdown  variant="link"  toggle-class="text-decoration-none" class="menu my-0" no-caret>
                        <template #button-content>
                            <b-icon icon="three-dots-vertical" style="color: rgb(var(--hot-food-color));"></b-icon>
                        </template>
                        <b-dropdown-item @click="clearCache()">{{$t("clear-cache")}}</b-dropdown-item>
                    </b-dropdown>
                </div>
                <div class="divider px-3"></div>
                <div class="sidebar-content px-3">
                    <div @click="expandHours = !expandHours" class="sidebar-content-header">
                        <span class="icon-magasin"></span>
                        <span class="content-title">{{$t("storeInfo")}}</span>
                        <span :class="{'icon-fleche-up': expandHours, 'icon-fleche-down' : !expandHours}"></span>
                    </div>
                    <b-collapse id="expand-hours" v-model="expandHours" class="mt-2">
                        <div class="sidebar-content-date">
                            <span class="days">{{$t("mondayToSaturday")}}</span>
                            <span class="hours">08:00am - 08:00pm</span>
                        </div>
                        <div class="sidebar-content-date">
                            <span class="days">{{$t("sunday")}}</span>
                            <span class="hours">09:00am - 08:00pm</span>
                        </div>
                    </b-collapse>
                </div>
                <div class="divider px-3"></div>
                <div class="sidebar-content px-3">
                    <div class="sidebar-content-header" @click="$router.push('/')">
                        <span class="icon-home sidebar-elmt-icon"></span>
                        <span class="content-title">{{$t("homeSidebarLabel")}}</span>
                    </div>
                </div>
                <div class="divider px-3"></div>
                <div class="sidebar-content px-3">
                    <div class="sidebar-content-header" @click="$router.push('/orders')">
                        <span class="icon-orders sidebar-elmt-icon"></span>
                        <span class="content-title">{{$t("ordersHistorySidebarLabel")}}</span>
                    </div>
                </div>
                <div class="divider px-3"></div>
                <div class="sidebar-content px-3">
                    <div class="sidebar-content-header">
                        <span class="icon-aide sidebar-elmt-icon"></span>
                        <span class="content-title">{{$t("needHelp")}}</span>
                    </div>
                </div>
                <div class="divider px-3"></div>
                <div id="chooseLang">
                    <span class="">{{$t('currentLang')}} <small id="langPopover" class="changeLangButton">({{$t('changeLang')}})</small></span>
                </div>
                <div class="newVersion">
                    <small>Version {{ version }}</small>
                </div>
                <b-popover :show.sync="showLangPopover" target="langPopover" placement="bottom" triggers="click focus">
                    <ul class="changeLangList">
                        <template  v-for="(lang, key , index) in this.langs">
                            <li @click="changeLang(key)" :key=key+0>{{lang}}</li>
                            <b-dropdown-divider :key=key+1 v-if="index !== Object.keys(langs).length - 1"></b-dropdown-divider>
                        </template>
                    </ul>
                </b-popover>
                <div class="account-button" @click="onAccountBtnClick()">
                    <span class="icon-profil sidebar-elmt-icon"></span>
                    <span>{{$t("accountButtonLabel")}}</span>
                </div>
            </div>
        </div>
        <nav class="header-menu">
            <b-collapse id="collapse-4">
                <div @click="goSchedule()" class="menu-element">
                    <i class="mdi mdi-store elment-icon"></i>
                    <i class="elment-text">{{$t("storeOpeningHours")}}</i>
                </div>
            </b-collapse>
            <b-collapse id="collapse-offline">
                <div class="offline-bar">
                    {{$t("offlineText")}}
                </div>
            </b-collapse>
        </nav>
    </div>
</template>

<script>
    const Basket = require("@/assets/js/Food/Basket");
    import {getConfig} from "@/assets/js/Utils";
    var en = require('moment/locale/en-gb');
    var fr = require('moment/locale/fr');
    var es = require('moment/locale/es');
    var de = require('moment/locale/de');

    export default {
        name : 'Header',
        props : {
            nbProductsCart: {
                type: Number
            },
            isOffline: {
                type: Boolean
            },
            totalAmount: {
                type: Number
            },
            title:{
                type: String,
            },
            description: {
                type: String
            },
            showBasket: {
                type: Boolean,
                default: true
            },
            titleAtRight: {
                type: Boolean,
                default: false
            }
        },
        data(){
            return{
                base: localStorage.getItem("DKC_base"),
                showMenu: false,
                offlineBar: true,
                animateCart: false,
                showSidebar: false,
                expandHours: true,
                showLangPopover: false,
                lang: this.$root.$i18n.locale,
                version: getConfig().pwaVersion
            }
        },
        methods: {
            redirect: function(){
                this.$parent.closeHandler();
                this.$router.push("/");
            },
            goBack(){
                if(this.$route.name === "FoodPaymentDone" || this.$route.name === "FoodPaymentTicket" ){
                    this.$router.push({name: "FoodIndex"})
                }else{
                    this.$router.go(-1)
                }
            },
            goSchedule(){
                this.$router.push('/schedule');
            },
            changeLang(newLang){
                if(!Object.keys(this.langs).includes(newLang))
                    newLang = "en";
                localStorage.setItem("IEC_lang", newLang)
                this.$root.$i18n.locale = newLang;
                this.lang = newLang;
                this.$moment.locale(newLang)
                /*switch (newLang){
                    case "en":
                        this.$moment.locale(en)
                        break
                    case "fr":
                        this.$moment.locale(fr)
                        break
                    case "es":
                        this.$moment.locale(es)
                        break
                    case "de":
                        this.$moment.locale(de)
                        break
                }*/
                this.showLangPopover = false;
                window.location.reload()
                console.log(this.$i18n)
            },
            clearCache(){
                localStorage.clear()
                sessionStorage.clear()
                window.location.reload()
            },
            onAccountBtnClick(){
                window.location.replace("https://wl-dsp-click-and-collect.pubqlf.co.as8677.net/");
            }
        },
        created(){
            this.logoUrl = require("@/assets/img/wl-horizontal-logo.png")

            let loadedDemo = sessionStorage.getItem("IEC_loadedDemo")
            if(loadedDemo && loadedDemo == "leader-price"){
                this.logoUrl = require("@/assets/img/logo-leader-price.png")
            }
        },
        mounted(){
            this.$emit("headerHeight", this.$refs.header.offsetHeight)
        },
        computed:{
            iconToShow(){
                if(this.$route.name === "FoodIndex" || this.$route.name === "FoodScan"){
                    return this.showMenu === true ? 'close' : 'menu'
                }
                return 'chevron'
            },
            fromattedTotalAmount(){
                let basket = Basket.getLocalBasket()
                if(basket && basket.loyaltyToUse){
                    return (this.totalAmount - basket.loyaltyToUse).toFixed(2)
                }
                return this.totalAmount.toFixed(2)
            },
            showStep(){
                return (this.$route.name === "FoodPaymentMethods" || this.$route.name === "FoodBasket")
            },
            stepToShow(){
                switch(this.$route.name){
                    case "FoodBasket":
                        return this.$t("basketStep")
                    case "FoodPaymentMethods":
                        return this.$t("paymentStep")

                }
            },
            enableOfflineBar(){
                return (this.$route.name === "FoodScan" || this.$route.name === "FoodIndex") && this.isOffline
            }
        },
        watch:{
            nbProductsCart: function() {
                this.animateCart = true
                setTimeout(() => this.animateCart = false, 1000)
            },
            enableOfflineBar(){
                this.$root.$emit('bv::toggle::collapse', 'collapse-offline')
            }
        }
    }
</script>

<style >
.sidebar-header .menu .btn{
    box-shadow: inherit;
}
</style>
