<template>
    <div id="modalCrossSelling" class="row food-custom-modal">
        <b-modal id="modalCrossSelling"
                 ref="modalCrossSelling"
                 centered
                 :size="modalSize"
                 no-close-on-backdrop
                 no-close-on-esc
                 hide-header-close
                 header-text-variant="light"
                 header-class="modal-header-custom"
                 v-model="modalShow"
                 class="food-custom-modal"
                 :title="$t('modalCrossSellTitle')">

            
            <p class="modal-cross-sell-main-message">{{$t('modalCrossSellMainMessage')}} <span @click="showReceipe = ! showReceipe"><small><u>{{showReceipe ? $t('modalCrossSellHideReceipe') : $t('modalCrossSellShowReceipe')}}</u></small></span> </p>
            <div :class="{'receipe-hide': !showReceipe}" class="receipe-wrapper">
                <div class="receipe">
                    <div class="receipe-icon" :style="{backgroundImage: `url('${require('@/assets/img/cooking.png')}')`}"></div>
                    <ol class="receipe-text" v-html="$t('receipe')">
                    </ol>
                </div>
            </div>
            <p class="modal-cross-sell-explain-message">{{$t('modalCrossSellExplainMessage')}}</p>
            <div class="cross-sell-products-list">
                <div class="my-2">
                    <ProductBloc v-for="(product, key) in  productCrossSell" :key="key" :isCrossSell="true" :product="product" withBorder hideQty inCrossSell fullWidth @updateQty="$parent.basketChange()"></ProductBloc>
                </div>
            </div>

            <div slot="modal-footer" class="modal-actions">
                <!-- <button class="btn nok" @click="handleNo">{{$t("crossSellingNoButton")}}</button> -->
                <button class="btn ok" @click="handleOk">{{$t("crossSellingYesButton")}}</button>
            </div>


        </b-modal>
    </div>
</template>

<script>
    import {isNotNull} from "@/assets/js/Utils"
    import ProductBloc from "@/components/Food/ProductBloc";
    import {addProductToLocalBasket} from "@/assets/js/Food/Basket.js"

    export default {
        name: "ModalCrossSelling",
        inherit: true,
        components: {ProductBloc},
        props: {
            "productCrossSell": Array
        },
        data () {
            return {
                modalShow: false,
                modalNo: this.$t('crossSellingNoButton'),
                modalYes: this.$t('loyaltyCardModalBtnOk'),
                crossSellAccepted : false,
                modalSize: "lg",
                showReceipe: true
            }
        },
        mounted(){
            let fromCrossSell= localStorage.getItem("DKC_fromCrossSell") == "true";
            if(isNotNull(this.productCrossSell) && !fromCrossSell){
                var self = this;
                setTimeout(function(){
                    self.modalShow = true;
                }, 2000);
            }else{
                this.modalShow = false;
            }
    },
        methods: {
            handleOk () {
                this.modalShow = false;
                this.$parent.productCrossSell = null;
                // this.productCrossSell.forEach(product => {
                //     addProductToLocalBasket(product)
                // });
                // this.$parent.basketChange()
                sessionStorage.removeItem("IEC_productCrossSell");
            },
            handleNo(){
                this.modalShow = false;
                this.$parent.productCrossSell = null;
                sessionStorage.removeItem("IEC_productCrossSell");
            }
        },
        watch: {
            productCrossSell : function(){
                if(isNotNull(this.productCrossSell)){
                    var self = this;
                    setTimeout(function(){
                        self.modalShow = true;
                    }, 5000);
                }else{
                    this.modalShow = false;
                }
            }
        }
    }
</script>

<style scoped>
    .modal-cross-sell-main-message{
        text-align: center;
        font-weight: bold;
        font-size: 1.1rem;
        margin-bottom: 16px;
    }
    .modal-cross-sell-explain-message{
        font-style: italic;
        font-size: .9rem !important;
        margin-bottom: 8px;
    }   
    .receipe-icon{
        width: 36px;
        height: 36px;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
    }
    .receipe-wrapper{
        max-height: 200px;
        opacity: 1;
        transition: 0.2s ease-out all;
        overflow: hidden;
    }
    .receipe{
        opacity: 1;
        display: flex;
        flex-direction: column;
        padding: 4px;
        background: lightgray;
        border: solid 1px grey;
        border-radius: 12px;
        align-items: center;
        margin: 8px 0;
        overflow: hidden;
    }
    .receipe-hide{
        opacity: 0;
        max-height: 0;
        transition: 0.2s ease-in all;
    }
    .receipe-text{
        font-size: .85rem;
        font-style: italic;
    }
    .receipe-text li{
        margin: 4px 0;
    }
    .cross-sell-products-list{
        overflow: scroll;
        max-height: 300px;
    }
</style>
<style>
    #modalCrossSelling .modal-content{
        min-height: 550px;
    }
    #modalCrossSelling .modal-actions{
        justify-content: end !important;
    }
</style>