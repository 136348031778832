import {clearCache, clearCacheExceptCustomer, isNotNull, isStandalone, hexToRgb} from "./Utils";
import {getDemonstration} from "./Demonstration";
const demoFetchedEvent = document.createEvent('Event');
demoFetchedEvent.initEvent('demoFetchedEvent', true, true)
export function saveDeviceType(){
    let deviceType;
    let usrAgent = navigator.userAgent.toLowerCase();
    if((usrAgent.indexOf('iphone')>-1 || usrAgent.indexOf('ipad')>-1 || /^((?!chrome|android).)*safari/i.test(usrAgent) )){
        deviceType = "iphone";
    }else if(usrAgent.indexOf('android')>-1 ){
        deviceType = "android";
    }else{
        deviceType = "other";
    }

    let savedType = localStorage.getItem("IEC_deviceType") || null

    if(!savedType || deviceType != savedType){
        localStorage.setItem("IEC_deviceType", deviceType)
    }
}

// Detects if device is on iOS
const isIos = () => {
    const userAgent = window.navigator.userAgent.toLowerCase();
    return /iphone|ipad|ipod/.test(userAgent);
}

export function checkParameters(pathname, self) {
    var pathnameArray = pathname.split('/');
    var parameters;

    //redirection avec p comme paramètre pour accès par sms
    var p = self.$route.query.p;
    if(isNotNull(p)){
        let univers = pathnameArray[1]
        var urlRedirect = window.location.origin + "/" + univers + "/p=" + p +"/";
        if(univers == "fuel"){
            urlRedirect += "account?f=" + self.$route.query.f + "&l=" + self.$route.query.l;
        }
        if(self.$route.query.ticketId){
            urlRedirect += "ticket?ticketId=" + self.$route.query.ticketId;
        }
        window.location.replace(urlRedirect);
    }


    if(isNotNull(pathname.indexOf("p="))){
        for(var i = 0; i < pathnameArray.length; i++){
            if(pathnameArray[i].startsWith("p=")){
                parameters = pathnameArray[i].substring(2);
            }
        }

        var referer;
        var univers;
        var demoId;
        var scanType;
        var complexIdParent;

        if(isNotNull(parameters)){
            var parametersArray = parameters.split('_');
            if(parametersArray != null && parametersArray.length >= 4){
                referer = parametersArray[0]; //DKS or DKC
                univers = parametersArray[1]; //1=FASHION 2=FOOD 3=FUEL
                demoId = parametersArray[2]; //complexId of the demo
                scanType = parametersArray[3]; //Q=QRCode E=ean QE=QRcode + ean
                if(parametersArray.length >= 5){
                    complexIdParent = parametersArray[4];
                }
            }
            var manifest = document.getElementById('manifest-placeholder');
            // Checks if should display install popup notification:
            if (isIos()) {
                //this.setState({ showInstallMessage: true });
                manifest.href= window.location.origin + "/manifests/ios/manifest-"+ pathnameArray[1] +".json";
            }else{
                manifest.href= window.location.origin + "/manifests/other/manifest-" + pathnameArray[1] + ".json";
                Notification.requestPermission(function (status) {
                    // Cela permet d'utiliser Notification.permission avec Chrome/Safari
                    if (Notification.permission !== status) {
                        Notification.permission = status;
                    }
                });
            }
            
        }

        var oldDemoId = localStorage.getItem("DKC_demoId");
        var oldUnivers = localStorage.getItem("DKC_univers");
        if(!isNotNull(oldDemoId) || oldDemoId != demoId ){
            clearCache();
            if(!isNotNull(self.$route.query.ticketId))
                localStorage.setItem("DKC_needRefresh", "true");
            else
                window.location.reload()
        }

        if(!isNotNull(oldUnivers) || oldUnivers != univers){
            localStorage.setItem("DKC_univers", univers);
            clearCacheExceptCustomer();
        }

        if(isNotNull(referer) && isNotNull(univers) && isNotNull(demoId) && isNotNull(scanType)) {
            if (referer == "DKC" && isNotNull(complexIdParent)) {
                localStorage.setItem("DKC_complexIdParent", complexIdParent);
            }
            localStorage.setItem("DKC_referer", referer);
            localStorage.setItem("DKC_univers", univers);
            localStorage.setItem("DKC_demoId", demoId);
            localStorage.setItem("DKC_shortScanType", scanType);
            getDemonstration(demoId, response => {
                if(!response.error){
                    
                    if(isNotNull(response.color)){
                        let rgb = hexToRgb(response.color)
                        document.documentElement.style.setProperty('--hot-food-color', rgb.join(','));
                        localStorage.setItem("DKC_food_color", response.color);
                    }
                    if(isNotNull(response.city)){
                        localStorage.setItem("DKC_food_city", response.city);
                    }
                    if(isNotNull(response.clientBrand)){
                        localStorage.setItem("DKC_food_brand", response.clientBrand);
                    }
                    document.dispatchEvent(demoFetchedEvent);
                }
            })
            if (scanType == "Q") {
                scanType = "QRCODE"
            } else if (scanType == "E") {
                scanType = "BARCODE"
            } else {
                scanType = "QRCODE"
            }
            localStorage.setItem("DKC_scanType", scanType);
            saveDeviceType();
        }
    }else if(isStandalone()){
        self.$router.push('/');
    }else{
        //TODO : rediriger vers page de scan pour scanner un QR de partage ou autre + explication
        self.$router.push('/need/scan?needParameters=true');
    }

}

