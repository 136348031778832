import {getConfig, isNotNull, sendMails} from "./Utils";

var app = getConfig();

var basket = {
    products: [],
    lockedProducts: [],
    totalAmount: 0
};

var fullBasket = [];

function initBasket(){
    if(JSON.parse(localStorage.getItem("IEC_basket"))){
        basket = JSON.parse(localStorage.getItem("IEC_basket"));
    }
}

export function getBasket() {
    initBasket();
    return basket;
}

export function addProductToLocalBasket(productToAdd){
    console.log(productToAdd)
    var added = false;
    var productEan = productToAdd.ean.split("_")[0];
    initBasket();
    basket.products.some(function(element){
        if(element.product.ean == productEan && productToAdd.ean.indexOf("_") > -1 && element.qtyLocked){
            element.qty++;
            basket.lockedProducts.push(productToAdd.ean);
            added = true;
            return true;
        }else if(element.product.ean == productEan && !element.qtyLocked){
            element.qty++;
            added = true;
            return true;
        }
    })
    if(!added){
        var tmpProduct = {};
        tmpProduct.id = productToAdd.ean;
        tmpProduct.qty = 1;
        tmpProduct.qtyLocked = false;

        if(productToAdd.ean.indexOf("_") > -1){
            tmpProduct.qtyLocked = true;
            basket.lockedProducts.push(productToAdd.ean);
            productToAdd.ean = productEan;
        }
        tmpProduct.product = productToAdd;
        basket.products.push(tmpProduct);
    }
    basket.totalAmount += productToAdd.price;
    localStorage.setItem("IEC_basket", JSON.stringify(basket));
}

export function decreaseProductFromLocalBasket(productToDecrease) {
    initBasket();
    basket.products.some(function(element, index){
        if (element.product.ean == productToDecrease.ean && !element.qtyLocked) {
            if (element.qty > 1) {
                element.qty -= 1;
            } else {
                basket.products.splice(index, 1);
            }
            basket.totalAmount -= productToDecrease.price;
            return true;
        }
    })
    localStorage.setItem("IEC_basket", JSON.stringify(basket));
}

export function deleteProductFromLocalBasket(productToDelete) {
    initBasket();
    basket.products.forEach(function(element, index) {
        if (element.id == productToDelete.id) {
            basket.products.splice(index, 1);
            if(element.qtyLocked){
                var tmpLockedProducts = [];
                basket.lockedProducts.forEach(function(lockedProductEan) {
                    if(lockedProductEan.split("_")[0] != element.product.ean){
                        tmpLockedProducts.push(lockedProductEan);
                    }
                });
                basket.lockedProducts = tmpLockedProducts;
            }
            basket.totalAmount -= productToDelete.product.price * productToDelete.qty;
        }
    })
    localStorage.setItem("IEC_basket", JSON.stringify(basket));
}

export function emptyLocalBasket() {
    initBasket();
    basket.products = [];
    basket.lockedProducts = [];
    basket.totalAmount = 0;
    localStorage.setItem("IEC_basket", JSON.stringify(basket));
    app.appContext.basketChange();
}

function formatBasketBeforeUpdate() {
    initBasket();
    var formatedBasket = {
        productsCart: []
    };
    basket.products.forEach(function(element) {
        formatedBasket.productsCart.push({
            productId: element.product.id,
            qty: element.qty
        });
    })

    return formatedBasket;
}

export function updateBasket(callback) {
    var path = "/basket/addOrUpdateProduct";
    var url = app.DKW_URL + path;
    var formatedBasket = formatBasketBeforeUpdate();
    var request = new XMLHttpRequest();
    var params = "token=" + localStorage.getItem("DKC_fashion_token") + "&products=" + JSON.stringify(formatedBasket);
    request.onreadystatechange = function() {
        if (request.readyState === XMLHttpRequest.DONE) {
            if (request.status === 200) {
                var response = JSON.parse(request.response);
                if(response.error == false){
                    getCustomerBasket(callback);
                }else{
                    app.appContext.showAlert(app.appContext.$t('errorUpdateBasket'),"error");
                }
            }else{
                app.appContext.showAlert(app.appContext.$t('errorUpdateBasket'),"error");
            }
        }
    };
    request.onerror = function(e){
        return false
    }
    request.open('POST', url, false);
    request.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');

    request.send(params);
}

export function validBasket(callback) {
    var path = "/basket/validBasket";
    var url = app.DKW_URL + path;
    var request = new XMLHttpRequest();
    var params = "token=" + localStorage.getItem("DKC_fashion_token");
    request.onreadystatechange = function() {
        if (request.readyState === XMLHttpRequest.DONE) {
            if (request.status === 200) {
                var response = JSON.parse(request.response);
                if(response.error == false){
                    localStorage.setItem("DKC_encodedTicketId", response.basketComplexId);
                    getCustomerBasket(callback);
                    var ticketUrl = window.location.origin + "/ticket?ticketId=" + response.basketComplexId + "&demoId=" + localStorage.getItem("DKC_demoId") + "&univers=" + localStorage.getItem("DKC_univers");
                    sendMails(ticketUrl);
                }else{
                    app.appContext.showAlert(app.appContext.$t('errorValidBasket'),"error");
                }
            }else{
                app.appContext.showAlert(app.appContext.$t('errorValidBasket'),"error");
            }
        }
    };
    request.open('POST', url, true);
    request.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');

    request.send(params);
}

export function getCustomerBasket(callback) {
    var path = "/basket/getByCustomerId";
    var url = app.DKW_URL + path;
    var request = new XMLHttpRequest();
    var params = "?token=" + localStorage.getItem("DKC_fashion_token");
    fullBasket = {
        id: 0,
        totalAmount: 0,
        products: [],
        lockedProducts:[]
    };
    var products = [];
    request.onreadystatechange = function() {
        if (request.readyState === XMLHttpRequest.DONE) {
            if (request.status === 200) {
                var response = JSON.parse(request.response);
                response.forEach(function(customerBasket) {

                    if (customerBasket.state == "IN_PROGRESS") {
                        fullBasket.id = customerBasket.id;
                        fullBasket.totalAmount = customerBasket.totalAmount;
                        products = customerBasket.basketProducts;
                        fullBasket.state = customerBasket.state;
                    }
                });
                if (products) {
                    let tmpProduct;
                    products.forEach(function(product) {
                        tmpProduct = product;
                        tmpProduct.scannedBarcode = false;
                        tmpProduct.qtyLocked = false;
                        fullBasket.products.push(tmpProduct)
                    })
                }
                if (callback != null) {
                    if (fullBasket.products) {
                        callback(fullBasket);
                    } else {
                        callback(fullBasket);
                    }
                }
            }else{
                if (localStorage.getItem('IEC_basket')) {
                    callback(JSON.parse(localStorage.getItem('IEC_basket')));
                } else {
                    callback(fullBasket);
                }
            }
        }
    };
    if (localStorage.getItem("DKC_fashion_token")) {
        request.open('GET', url + params, false);
        request.send();
    } else {
        callback(fullBasket);
    }
}

export function getTicket(encodedTicketId, callback) {
    var path = "/basket/getBasketByComplexId";
    var url = app.DKW_URL + path;
    var request = new XMLHttpRequest();
    var params = "?complexId=" + encodedTicketId;
    request.onreadystatechange = function() {
        if (request.readyState === XMLHttpRequest.DONE) {
            if (request.status === 200) {
                var response = JSON.parse(request.response)
                callback(response);
            }else{
                app.appContext.showAlert(app.appContext.$t('errorGetTicket'),"error");
            }
        }
    };
    request.open('GET', url + params, true);
    request.send();
}

export function clearBasket() {
    if(isNotNull(localStorage.getItem("DKC_fashion_token"))){
        app = getConfig();
        var path = "/basket/clear";
        var url = app.DKW_URL + path;
        var request = new XMLHttpRequest();
        var params = "token=" + localStorage.getItem("DKC_fashion_token");
        request.onreadystatechange = function() {
            if (request.readyState === XMLHttpRequest.DONE) {
                if (request.status === 200) {
                    var response = JSON.parse(request.response);
                    if(response.error == false){
                        emptyLocalBasket();
                    }else{
                        app.appContext.showAlert(app.appContext.$t('errorClearBasket'),"error");
                    }
                }else{
                    app.appContext.showAlert(app.appContext.$t('errorClearBasket'),"error");
                }
            }
        };
        request.open('POST', url, true);
        request.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');

        request.send(params);
    }
}