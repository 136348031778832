<template>
    <div id="modalIdentification" class="row">
        <b-modal id="modalIdentification"
                 ref="modalIdentification"
                 centered
                 size="lg"
                 no-close-on-backdrop
                 no-close-on-esc
                 hide-header
                 hide-footer
                 v-model="modal"
                 :title="$t('titleModalIdentification')">
            <div class="food-custom-modal modal-identification">

                <div id="chooseLang" class="float-right">
                    <span class="">{{$t('currentLang')}} <small id="langPopover" class="changeLangButton">({{$t('changeLang')}})</small></span>
                </div>
                <b-popover :show.sync="showLangPopover" target="langPopover" placement="bottom" triggers="click focus">
                    <ul class="changeLangList">
                        <template  v-for="(lang, key , index) in this.langs">
                            <li @click="changeLang(key)" :key=key+0>{{lang}}</li>
                            <b-dropdown-divider :key=key+1 v-if="index !== Object.keys(langs).length - 1"></b-dropdown-divider>
                        </template>
                    </ul>
                </b-popover>

                <p class="identification-explanation" v-html="$t('modalIdentificationExplanation')"></p>
                <b-alert variant="danger" v-model="error" dismissible>{{errorMessage || $t('errorRegisterModalIdentification')}}</b-alert>
                <b-form-group id="labelFirstname"
                            :label="$t('firstname') + ' : '"
                            label-for="inputFirstname">
                    <b-form-input id="inputFirstname"
                                    type="text"
                                    class="custom-input"
                                    v-model="firstname"
                                    required>
                    </b-form-input>
                </b-form-group>

                <b-form-group id="labelLastname"
                            :label="$t('lastname') + ' : '"
                            label-for="inputLastname">
                    <b-form-input id="inputLastname"
                                type="text"
                                class="custom-input"
                                v-model="lastname"
                                required>
                    </b-form-input>
                </b-form-group>

                <b-form-group id="labelEmail"
                            :label="$t('emailLabel')"
                            label-for="inputEmail">
                    <b-form-input id="inputEmail"
                                type="text"
                                class="custom-input"
                                v-model="email"
                                required>
                    </b-form-input>
                </b-form-group>
                <div class="delete-modal-actions">
                    <span class="btn ok" @click="handleOk">OK</span>
                </div>
            </div>
        </b-modal>
    </div>
</template>

<script>
    var customer = require("@/assets/js/Customer")
    export default {
        name: "Modal",
        data () {
            return {
                modal: true,
                modalTitle: this.$t('titleModalIdentification'),
                lastname: "",
                firstname: "",
                email: "",
                showLangPopover: false,
                lang: "en",
                error: false,
                errorMessage: null
            }
        },
        created(){
            console.log("Created Modal identification")
        },
        methods: {
            handleOk (evt) {
                evt.preventDefault()
                if (this.lastname == "" || this.firstname == "" || !this.checkValidEMail()) {
                    alert(this.$t('errorModalIdentification'))
                } else {
					this.$parent.showLoader = true;
                    customer.registerCustomer({
                        firstName: this.firstname,
                        lastName: this.lastname,
                        email: this.email,
                        phoneNumber: this.phoneNumber,
                    }, (data) => {
                        if(data.error){
                            this.error = true
                            this.errorMessage = data.message || null
                            this.$parent.showLoader = false
                        }else{
                            window.location.replace(window.location.origin)
                            this.$parent.showLoader = false;
                        }
                    })
                }
            },
            changeLang(newLang){
                if(!Object.keys(this.langs).includes(newLang))
                    newLang = "en";
                localStorage.setItem("IEC_lang", newLang)
                this.$root.$i18n.locale = newLang;
                this.lang = newLang;
                this.showLangPopover = false;
                window.location.reload()
            },
            checkValidEMail(){
                const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                return pattern.test(this.email)
            }
        }
    }
</script>